<template>
  <div class="home">
    <section class="hero is-fullheight back">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-vcentered">
            <div class="column has-text-centered">

              <p>ZIMNÁ HRA</p>
              <h1>ŠKRIATKOVO KÚZLO</h1>
              <p>
                Hru Škriatkovo kúzlo si budete môcť v Bratislave opäť
                zahrať budúcu zimnú sezónu / od novembra 2025.
                <br>
                Tešíme sa na vás!
              </p>
              <p>
                Ďakujeme partnerom, ktorí nám pomohli hru<br>v Bratislave zrealizovať.
              </p>
              <img src="/images/loga.png" alt="" class="mt-6">
            </div>
            <div class="column">
              <img src="/images/spinka.gif" alt="Skriatok spinka">
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'NewHome',
};
</script>

<style lang="scss" scoped>
@import "~bulma/bulma";
$skriatkovia-black: #341511;
$skriatkovia-red: #9B1216;
$skriatkovia-yellow: #CB8E00;
.logo {
  margin: 10px 20px;
}
.back {
  background: URL('/images/home-back.jpg');
  background-size: cover;
  background-position: left center;
}
h1{
  font-family: 'Caladea', serif;
  font-size: 80px;
  font-weight: bold;
  color: $skriatkovia-red;
  @media(max-width: $tablet) {
    font-size: 50px;
  }
}
p{
  color: $skriatkovia-black;
  font-family: 'Bebas Neue', cursive;
  font-size: 36px;
  @media(max-width: $tablet) {
    font-size: 1.5rem;
  }
}
.yellow {
  margin: 30px 0;
  color: $skriatkovia-yellow;
}

</style>
